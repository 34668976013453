import { useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import LowerCard from '../../assets/Icon/LowerCard.png';
import UperCard from '../../assets/Icon/UperCard.png';
import './Style.css';

function Navbar({ img, headerText, BodyText }) {
  return (
    <div
      className="card"
      style={{ width: '18rem', marginTop: '2rem', marginBottom: '2rem',borderRadius:'30px' }}
    >
      {/* <img src="..." className="card-img-top" alt="..."> */}
      <div className="sliderImgCont d-flex justify-content-center align-items-center">
        <img src={img} alt={'/'} className="slideImg" />
      </div>
      <div className="sliderInfoCon">
        <div className="d-flex justify-content-end">
          <img
            src={UperCard}
            className="d-flex jalign-items-end"
            style={{ marginRight: '5%' }}
          />
        </div>
        <p className="sliderText">{headerText}</p>
        <div className="d-flex justify-content-start" style={{ marginLeft: '5%' }}>
          <img src={LowerCard} />
        </div>
        <div className="exploreBtn">
          <p style={{ textAlign: 'center', fontWeight: 100 }}>{BodyText}</p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
