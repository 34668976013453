import React, { useState } from 'react';
import './Style.css';
import WhyChoseUsImage from '../../assets/images/WhyChoseUs.png';
import EllipseImage from '../../assets/images/Home/Ellipse.png';
import LeftArrowImage from '../../assets/images/Home/LeftArrow.png';
import RightArrowImage from '../../assets/images/Home/RightArrow.png';
const WhyChoseUs = ({  }) => {
  const TextArray = [
    'Reliable cleaning experts',
    'Personalized service options',
    'Your sanctuary, always clean and tidy',
    'Flexible scheduling options',
    'Trusted and recommended',
  ];

  return (
    <div className="container">
      <div className="bottom-left-img-why">
        <img src={LeftArrowImage} className="img-fluid" />
      </div>
      <div className="mainContainerwhycoiceus">
        <div className="d-flex justify-content-center">
          <h1 className="textWhyChoiceUs">
            Why
            <span
              style={{
                color: '#E23074',
              }}
            >
              {' '}
              Choose{' '}
            </span>
            us
          </h1>
        </div>
        <div className="row align-items-center">
          <div className="col-md-5 col-sm-12 ">
            <div className="d-flex  ">
              <img
                src={WhyChoseUsImage}
                className="image-fluid"
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="parentDiv">
              <div className="child">
                <ul style={{}} id="">
                  {TextArray.map((val) => {
                    return (
                      <li className="d-flex" style={{}}>
                        {/* <img
                          src={EllipseImage}
                          style={{ height: '16px', marginTop: '10px' }}
                        /> */}
                        <h2
                          style={{
                            width: '70%',
                            fontWeight: 300,
                            marginLeft: '10px',
                          }}
                        >
                          {val}
                        </h2>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="top-right-img-why">
              <img src={RightArrowImage} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoseUs;
