import React from 'react';
import Home from './routes/Home';
import About from './routes/About';
import Booking from './routes/Booking';
import Services from './routes/Services';

import { Route, Routes } from 'react-router-dom';
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/services" element={<Services />} />
      </Routes>
    </>
  );
}

export default App;
