import React, { useState } from "react";
import "./Style.css";
import LogoImage from "../../assets/images/AppIconNavBar.png";
import FaceBookImage from "../../assets/Icon/FaceBook.png";
import InstagramImage from "../../assets/Icon/Instagram.png";
import PinsImage from "../../assets/Icon/Pins.png";
import twitterImage from "../../assets/Icon/twitter.png";
import locImage from "../../assets/Icon/location_on.png";
import CallImage from "../../assets/Icon/call.png";
import emailImage from "../../assets/Icon/email.png";
// import {FaBeer}from 'react-icons/fa'
const BottomComponent = ({}) => {
  return (
    <section className="mainContainerBottom">
      <div className="d-flex row ">
        <div className="col-md-3">
          <a className="navbar-brand" href="/">
            <img src={LogoImage} width="65.9" height="101" alt="" />
          </a>
          <p className="bottom-comp-para">You are in good hands</p>
          <div className="d-flex">
            {/* <img src={twitterImage} className="SocialIcons" /> */}
            <a
              href="https://www.facebook.com/Yadiscleansolutions"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img src={FaceBookImage} className="SocialIcons" />
            </a>
            {/* <img src={PinsImage} className="SocialIcons" /> */}
            <a
              href="https://instagram.com/yadis_cleansolutions?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <img src={InstagramImage} className="SocialIcons" />
            </a>
          </div>
        </div>
        <div className="col-md-3">
          <h4 className="HeaderBottomComponent">Service</h4>
          <ul className="no-bullets">
            <li className="bottom-comp-list-item">Routine Cleaning</li>
            <li className="bottom-comp-list-item">Deep Cleaning</li>
            <li className="bottom-comp-list-item">Move In Or Out Cleaning</li>
            <li className="bottom-comp-list-item">
              Post Constriction Cleaning
            </li>
          </ul>
        </div>
        {/* <div className="col-md-2">
          <h4 className="HeaderBottomComponent">Links</h4>
          <ul className="no-bullets">
            <li className="bottom-comp-list-item">
              <a href="/" style={{ cursor: 'pointer' }}>
                Home
              </a>
            </li>
            <li className="bottom-comp-list-item">
              <a href="/services" style={{ cursor: 'pointer' }}>
                Services
              </a>
            </li>
            <li className="bottom-comp-list-item">
              <a href="/about" style={{ cursor: 'pointer' }}>
                About Us
              </a>
            </li>
            <li className="bottom-comp-list-item">
              <a href="/booking" style={{ cursor: 'pointer' }}>
                Book Online
              </a>
            </li>
          </ul>
        </div> */}
        <div className="col-md-3">
          <h4 className="HeaderBottomComponent">Services Areas</h4>
          <ul className="no-bullets">
            <li className="bottom-comp-list-item"> Spring, Texas</li>
            <li className="bottom-comp-list-item">Clearlake, Texas</li>
            <li className="bottom-comp-list-item">Missouri city, Texas</li>
            <li className="bottom-comp-list-item">Bellaire, Texas</li>
          </ul>
        </div>
        {/* <div className="col-md-3">
          <h4 className="HeaderBottomComponent">Join a Newsletter</h4>
          <input
            type="text"
            className="textInputBottom rounded-5"
            placeholder="Enter Your Email"
          />
        </div> */}
      </div>
      <div className="d-flex justify-content-end">
        Design and Built by
        <a
          href="https://hegemonicsoftwares.com/"
          target="_blank"
          style={{ cursor: "pointer", marginLeft: "3px" }}
        >
          Hegemonic Software & Mobile Applications
        </a>
      </div>
      <hr className="height:2px;border-width:0;color:gray;background-color:gray" />
      <div className="d-flex row ">
        <div className="col-md-6">
          <p>Copy Right Yadi’s Clean Solutions - All Right Reserved</p>
        </div>
        <div className="col-6 d-lg-flex justify-content-between">
          <div className="bottomLocContainer">
            <img src={locImage} className="iconImageStyle" />
            <p>Houston, Tx.</p>
          </div>
          <div className=" bottomLocContainer">
            <img src={emailImage} className="iconImageStyle" />
            <p>Info@yadiscleansolutions.com</p>
          </div>
          <div className="bottomLocContainer">
            <img src={CallImage} className="iconImageStyle" />
            <p>(346) 755 2321</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomComponent;
