import { useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './navbarStyle.css';
function Navbar({ bookingBtn = true }) {
  const navRef = useRef();
  const [show, setShow] = useState(false);
  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav');
    setShow(!show);
  };
  return (
    <header
      style={{
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
      }}
    >
      <a className="navbar-brand" href="/">
        {/* <img src={LogoImage} width="65.9" height="101" alt="" /> */}
      </a>

      <div style={{ zIndex: 99 }}>
        <nav ref={navRef} style={{ backgroundColor: show ? '#ffabcb' : null }}>
          <a href="/" style={{ cursor: 'pointer' }}>
            Home
          </a>
          <a href="/services" style={{ cursor: 'pointer' }}>
            Services
          </a>
          <a href="/booking" style={{ cursor: 'pointer' }}>
            Book Online
          </a>
          <a href="/about" style={{ cursor: 'pointer' }}>
            About Us
          </a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        {bookingBtn ? (
          <button
            className=" button4"
            style={{
              position: 'absolute',
              right: '20px',
              zIndex: 100,
              // backgroundColor: 'white',
              fontSize: '18px',
            }}
            onClick={() => {
              window.location.href = '/booking';
            }}
          >
            Book Cleaning Service
          </button>
        ) : null}
      </div>
      <button className="nav-btn" onClick={showNavbar} style={{ zIndex: 1 }}>
        <FaBars />
      </button>

      {/* {
        (navRef?.current?.hidden = false ? (
          <button className="button button4">Book Cleaning Service</button>
        ) : null)
      } */}
    </header>
  );
}

export default Navbar;
