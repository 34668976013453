import React from 'react';

import RightArrowImage from '../../assets/images/Home/RightArrow.png';
import LeftArrowImage from '../../assets/images/Home/LeftArrow.png';
const RouteinCleaningSection = ({
  pinkTitle,
  whiteTitle,
  paragraph,
  listContent1,
  listContent2,
  listContent3,
  listContent4,
  listContent5,
  listContent6,
  listContent7,
  routeinClean,
  rowReverse,
  refProp,
}) => {
  return (
    <div className="container" ref={refProp}>
      <div
        className={`${
          rowReverse
            ? 'flex justify-between items-center flex-row-reverse'
            : 'flex justify-between items-center'
        }`}
      >
        <h3 className="text-black lg:text-6xl sm:text-4xl  font-semibold">
          <span className="headerpink" style={{textDecoration: 'underline' }}> {pinkTitle} </span>
          {whiteTitle}
        </h3>
        {pinkTitle != 'Deep' ? (
          <div className="bottom-left-img-why">
            <img src={LeftArrowImage} className="img-fluid" />
          </div>
        ) : (
          <div className="top-right-img-why">
            <img src={RightArrowImage} className="img-fluid" />
          </div>
        )}
      </div>
      <div
        className={`${
          rowReverse ? 'flex flex-row-reverse flex-wrap ' : 'flex flex-wrap'
        }`}
      >
        <div className="col-md-4">
          <img
            src={routeinClean}
            alt="Image"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="w-full md:w-1/2 p-2 ">
          <p className="md:text-3xl md:text-1xl mb-2 mt-md-5 ">{paragraph}</p>
          <ul className="">
            <li className="md:text-3xl md:text-1xl pt-4 ">{listContent1}</li>
            <li className="md:text-3xl md:text-1xl py-4">{listContent2}</li>
            <li className="md:text-3xl md:text-1xl">{listContent3}</li>
            <li className="md:text-3xl md:text-1xl py-4">{listContent4}</li>
            <li className="md:text-3xl md:text-1xl">{listContent5}</li>
            <li className="md:text-3xl md:text-1xl py-4">{listContent6}</li>
            {listContent7 ? (
              <li className="md:text-3xl md:text-1xl py-4">{listContent7}</li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RouteinCleaningSection;
