import React, { useState,useEffect } from 'react';
import Navbar from '../../Components/NavBar';
import HomeGirlImage from '../../assets/images/HomeGirlImage.png';
import LOGOImage from '../../assets/images/LOGO.png';
import OvalImage from '../../assets/images/Home/Oval.png';
import BannerRightSquareImage from '../../assets/images/Home/BannerRightSquare.png';
import './home.css';
import ServiceOffer from '../../Components/ServiceOffer';
import WhyChoseUs from '../../Components/WhyChoseUs';
import HowItWorks from '../../Components/HowItWorks';
import SayGoodBy from '../../Components/SayGoodBy';
import BottomComponent from '../../Components/BottomComponent';

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  })
  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="topContainer">
        <div style={{ zIndex: 99 }}>
          <Navbar bookingBtn={false} />
        </div>
        <div className="container">
          <img
            className="img-fluid"
            src={LOGOImage}
            style={{
              // backgroundColor:'red',
              zIndex: 1,
              position: 'absolute',
              width: isDesktop?'15%':'25%',
              top: '10%',
              left: '8%',
            }}
          />
          <div className="d-md-flex col-12 ">
            <div
              className="col-md-8 col-sm-12 mb-10"
              style={{ marginTop: '15%' }}
            >
              <div className="col-md-4 col-sm-12">
                <div className="d-flex justify-content-center"></div>
              </div>
              <div className="headerTextContainer">
                {/* <h1 className="bannerHeaderh3">Yadi’s Clean Solution's</h1> */}
                <h1 className="bannerHeaderh1">
                  Discover the Art of
                  <br />
                  <span className="headerpink"> Cleanliness</span>
                </h1>
                <h4 className="bannerHeaderh4">
                  Extensive Cleaning Services to Meet Your Every Need
                </h4>
                <button
                  type="button"
                  className=" buttonHome1 col-md-4 col-sm-8 col-12"
                  onClick={() => {
                    window.location.href = '/booking';
                  }}
                >
                  Book Now{' '}
                </button>
                <button
                  type="button"
                  className=" buttonHome2 col-md-4 col-sm-8 col-12 mt-2"
                  onClick={() => {
                    window.location.href = '/services';
                  }}
                >
                  Our Services{' '}
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="d-flex justify-content-center">
                <img
                  className="col-12 "
                  src={HomeGirlImage}
                  style={{
                    zIndex: 1,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-right-img col-md-3 col-sm-3 col-3"
          style={{ zIndex: 0 }}
        >
          <img src={BannerRightSquareImage} alt="" className="img-fluid" />
        </div>
        <div className="top-left-img">
          <img src={OvalImage} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="ml-20 mt-10 mb-10 ">
        <div className="d-flex align-items-center">
          <div
            style={{ width: '50px', height: '3px', backgroundColor: 'black' }}
          />
          <h3 style={{ color: '#EA6397', marginLeft: '10px' }}>Explore</h3>
        </div>
        <div className="d-flex align-items-center">
          <div
            style={{
              width: '50px',
              height: '2px',
              backgroundColor: 'black',
              visibility: 'hidden',
            }}
          />

          <h1
            style={{
              fontWeight: 'bolder',
              fontSize: '50px',
              marginLeft: '10px',
            }}
          >
            Services
            <span style={{ color: '#EA6397' }}> We </span>
            Offer
          </h1>
        </div>
      </div>
      <ServiceOffer />

      <WhyChoseUs />
      <HowItWorks />
      <SayGoodBy />
      <BottomComponent />
    </div>
  );
}

export default App;
