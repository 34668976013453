import React, { useState } from 'react';
import './Style.css';
import wewillcleanImage from '../../assets/images/Home/wewillclean.png';
import ConfirmBookingImage from '../../assets/images/Home/ConfirmBooking.png';
import bookaCleaningImage from '../../assets/images/Home/bookaCleaning.png';
const HowItWorks = ({}) => {
  const TextArray = [
    {
      head: 'Book a Cleaning',
      body: 'Easily book your preferred date and time by clicking "Book Now"',
      image: bookaCleaningImage,
    },
    {
      head: 'Confirm Booking',
      body: 'Rest assured, we will confirm your booking and instructions securely',
      image: ConfirmBookingImage,
    },
    {
      head: `We'll Clean it`,
      body: 'Count on our trusted and experienced maids to arrive promptly at your door for a sparkling clean',
      image: wewillcleanImage,
    },
  ];

  return (
    <div className="container">
      <h1 className="header-container-howitwork-text">
        How it
        <span style={{ color: '#E23074', textDecoration: 'underline' }}>
          {' '}
          works
        </span>
      </h1>
      <p className="body-container-howitwork-text">
        Making it simple for you is our priority. Here's how it works
      </p>
      <div className="card-wrapper ">
        <div className="container">
          <div className="row">
            {TextArray.map((item) => {
              return (
                <div className="col-lg-4 mb-8">
                  <div className="card__main__wrapper d-flex flex-column align-items-center justify-content-center">
                    <div
                      className="imageStyleHowitWork"
                      style={{ width: '20%' }}
                    >
                      <img src={item.image} className="img-fluid" />
                    </div>
                    <h3 className="how-it-work-head-text">{item.head}</h3>
                    <p className="how-it-work-body-text">{item.body}</p>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-4 mb-8">
              <div className="card__main__wrapper">card</div>
            </div>
            <div className="col-lg-4 mb-8">
              <div className="card__main__wrapper">
                <img></img>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
