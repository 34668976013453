import React, { useState } from 'react';
import './Style.css';
import WhyChoseUsImage from '../../assets/images/WhyChoseUs.png';
import LeftArrowImage from '../../assets/images/Home/LeftArrow.png';
import RightArrowImage from '../../assets/images/Home/RightArrow.png';
import img1 from '../../assets/images/AboutUs/img1.png';
import img2 from '../../assets/images/AboutUs/img2.png';
import img3 from '../../assets/images/AboutUs/img3.png';
import img4 from '../../assets/images/AboutUs/img4.png';
import img5 from '../../assets/images/AboutUs/img5.png';
import img6 from '../../assets/images/AboutUs/img6.png';
import img7 from '../../assets/images/AboutUs/img7.png';
const SayGoodBy = ({}) => {
  const ObjectArray = [
    {
      img: img1,
      text: `We're Appointment Reliable, No Cancellations`,
    },
    {
      img: img2,
      text: 'Time-Saving Convenience',
    },
    {
      img: img3,
      text: 'Your Safety Matters Most',
    },
    {
      img: img4,
      text: 'Uncompromising Quality Assurance',
    },
    {
      img: img5,
      text: 'Effortlessly Seek Assistance',
    },
    {
      img: img6,
      text: 'Smooth Communication Experience',
    },
    {
      img: img7,
      text: 'Hassle-Free Payment Process',
    },
  ];

  return (
    <div className=" topContainerSayGoodby">
      <div className="container  d-flex flex-column align-items-center ">
        <div className="botom-left-img-say ">
          {/* <img src={RightArrowImage} className="img-fluid" /> */}
        </div>
        <h1 className="sayGoodbyh1">
          We place your
          <span id="headerColor"> Trust </span>
          and
          <span id="headerColor"> Security</span> at the forefront of our
          service
        </h1>
        <div className="row  d-flex justify-content-around">
          {ObjectArray.map((item) => {
            return (
              <div className="d-flex flex-column  col-md-3 col-sm-6">
                <img
                  src={item.img}
                  id="imageComp"
                  style={{ alignSelf: 'center' }}
                />
                <div
                  id="textComp"
                  style={{ alignSelf: 'center', fontWeight: '600' }}
                >
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
        <div className="top-right-img-say">
          {/* <img src={LeftArrowImage} className="img-fluid" /> */}
        </div>
      </div>
    </div>
  );
};

export default SayGoodBy;
