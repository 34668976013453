import React, { useState } from 'react';
import Navbar from '../../Components/NavBar';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import './Booking.css';
import Modal from 'react-modal';

import BottomComponent from '../../Components/BottomComponent';
import icon1 from '../../assets/images/Booking/icon1.png';
import icon2 from '../../assets/images/Booking/icon2.png';
import icon3 from '../../assets/images/Booking/icon3.png';
import icon4 from '../../assets/images/Booking/icon4.png';
import icon5 from '../../assets/images/Booking/icon5.png';
import icon6 from '../../assets/images/Booking/icon6.png';
import icon7 from '../../assets/images/Booking/icon7.png';
import icon8 from '../../assets/images/Booking/icon8.png';

import HomeIcon from '../../assets/images/Booking/rightside/HomeIcon.png';
import CalendarIcon from '../../assets/images/Booking/rightside/CalendarIcon.png';
import CircleRepeat from '../../assets/images/Booking/rightside/CircleRepeat.png';

import CashFreePayment from '../../assets/images/Booking/rightside/CashFreePayment.png';
import EasyToGetHelp from '../../assets/images/Booking/rightside/EasyToGetHelp.png';
import OnlyTheBestQuality from '../../assets/images/Booking/rightside/OnlyTheBestQuality.png';
import SafetyFirst from '../../assets/images/Booking/rightside/SafetyFirst.png';
import SavesYourTime from '../../assets/images/Booking/rightside/SavesYourTime.png';
import Seamlesscommunication from '../../assets/images/Booking/rightside/Seamlesscommunication.png';
import moment from 'moment';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
};

function Booking() {
  const [noOfBedRoom, setNoOfBedRoom] = useState('1');
  const [noOfWashRoom, setNoOfWashRoom] = useState('1');
  const [squareFeet, setSquareFeet] = useState('');
  const [selectExtra, setSelectExtra] = useState([]);
  const [selectOftenVisit, setSelectOftenVisit] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [aptNo, setAptNo] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [dateVisit, setDateVisit] = useState('');
  const [Instruction, setInstruction] = useState('');

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const setSelectExtraHabdler = (val) => {
    // console.log('noOfBedRoom', val);
    let arr = [...selectExtra];
    if (!arr.includes(val)) {
      arr.push(val);
    } else {
      arr.splice(arr.indexOf(val), 1);
    }

    setSelectExtra(arr);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const FormHandler = () => {
    if (!squareFeet) {
      toast.error('Enter Square Feet');
      return;
    } else if (!selectOftenVisit) {
      toast.error('Select how Often should we visit');
      return;
    } else if (!firstName) {
      toast.error('Enter First Name');
      return;
    } else if (!lastName) {
      toast.error('Enter Last Name');
      return;
    } else if (!email) {
      toast.error('Enter Your Email');
      return;
    } else if (email && !validateEmail(email)) {
      toast.error('Invalid Email');
      return;
    } else if (!address) {
      toast.error('Enter Your Address');
      return;
    } else if (!city) {
      toast.error('Enter Your City');
      return;
    } else if (!aptNo) {
      toast.error('Enter Your Apt/Suite NO');
      return;
    } else if (!state) {
      toast.error('Enter Your State');
      return;
    } else if (!zip) {
      toast.error('Enter Your Zip Code');
      return;
    } else if (!dateVisit) {
      toast.error('Select Appointment Date');
      return;
    } else {
      formBubmitionHandler();
    }
  };
  const formBubmitionHandler = () => {
    let data = {
      bedroom_no: noOfBedRoom + ' Bedrooms',
      washroom_no: noOfWashRoom + ' Bathroom',
      square_feet: squareFeet + ' sqft',
      extra_services: JSON.stringify(selectExtra),
      we_come: selectOftenVisit,
      first_name: firstName,
      last_name: lastName,
      email: email,
      address: address,
      city: city,
      province: aptNo,
      state: state,
      zipcode: zip,
      booking_date: dateVisit,
      message: Instruction,
    };

    console.log('data', data);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://www.api.yadiscleansolutions.com/api/order',
      data: data,
    };

    const myPromise = axios
      .request(config)
      .then((response) => {
        openModal();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    toast.promise(myPromise, {
      loading: 'Loading',
      success: 'Booking Successfully',
      error: 'Booking Failed',
    });
  };
  return (
    <div>
      <div className="topContainer">
        <Navbar bookingBtn={false} />
        <Toaster />

        <div className="row col-12">
          <div
            className=" "
            style={{ textAlign: 'center', minHeight: '300px' }}
          >
            <h1 id="headerBannerBooking">
              Book us
              <span className="headerpink"> in seconds </span>
              for a<br></br> hassle-free experience
            </h1>
            <h4 className="bannerHeaderh4">
              <b>
                Save time and money with our convenient online booking and flat
                rate pricing!
              </b>
            </h4>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-12 ">
            <div id="shodowcontainer">
              <form
                onSubmit={() => {
                  FormHandler();
                }}
              >
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <h5 className="d-flex justify-content-center">
                      Book Your Cleaning Service Now
                    </h5>
                    <p className="d-flex justify-content-center">
                      Let us know how we can assist you and make your space
                      shine!
                    </p>
                    <h5 id="formbookingInerheader">Choose Your Service</h5>
                    <p id="formbookingInerparagraph">
                      Our best pricing is available for whole home cleanings.
                      For extra large or heavily soiled homes, pricing may vary.
                      Please contact us for partial home cleans
                    </p>
                  </div>
                  <div className="form-group col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control mb-2"
                        onChange={(val) => {
                          setNoOfBedRoom(val.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select number of Bedroom
                        </option>

                        <option value={1}>1 Bedroom</option>
                        <option value={2}>2 Bedroom</option>
                        <option value={3}>3 Bedroom</option>
                        <option value={4}>4 Bedroom</option>
                        <option value={5}>5 Bedroom</option>
                      </select>
                    </div>
                    <div className="form-group mb-2">
                      <select
                        className="form-control"
                        onChange={(val) => {
                          setNoOfWashRoom(val.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select number of Bathroom
                        </option>

                        <option value={1}>1 Bathroom</option>
                        <option value={2}>2 Bathroom</option>
                        <option value={3}>3 Bathroom</option>
                        <option value={4}>4 Bathroom</option>
                        <option value={5}>5 Bathroom</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control mb-4"
                        placeholder="Square Feet"
                        required
                        min="0"
                        onChange={(val) => {
                          setSquareFeet(val.target.value);
                        }}
                      />
                    </div>
                    <div className="Line mt-4 mb-4" />
                  </div>
                  <div className="form-group col-md-12">
                    <h5 id="formbookingInerheader">Select Extras</h5>
                    <p id="formbookingInerparagraph">
                      Customize your cleaning by selecting additional items you
                      need to be cleaned. If your home requires extra attention
                      due to irregular maintenance, we recommend a deep
                      cleaning. Please note that add-ons must be selected for
                      all types of cleanings.
                    </p>
                    <div className="row justify-content-around mt-3">
                      <button
                        type="button"
                        className=" bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('Deep Cleaning')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('Deep Cleaning');
                        }}
                      >
                        <img src={icon1} className="pb-2" />
                        <p className="BookingBtnFormText">Deep Cleaning</p>
                      </button>
                      <button
                        type="button"
                        className=" bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('Inside Fridge')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('Inside Fridge');
                        }}
                      >
                        <img src={icon2} className="pb-2" />
                        <p className="BookingBtnFormText">Inside Fridge</p>
                      </button>
                    </div>
                    <div className="row justify-content-around mt-3">
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('Inside Oven')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('Inside Oven');
                        }}
                      >
                        <img src={icon3} className="pb-2" />
                        <p className="BookingBtnFormText">Inside Oven</p>
                      </button>
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('We Have Pets')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('We Have Pets');
                        }}
                      >
                        <img src={icon4} className="pb-2" />
                        <p className="BookingBtnFormText">We Have Pets</p>
                      </button>
                    </div>
                    <div className="row justify-content-around mt-3">
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('Clean Inside Windows')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('Clean Inside Windows');
                        }}
                      >
                        <img src={icon5} className="pb-2" />
                        <p className="BookingBtnFormText">
                          Clean Inside Windows
                        </p>
                      </button>
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('clean inside kitchen cabinets')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler(
                            'clean inside kitchen cabinets'
                          );
                        }}
                      >
                        <img src={icon6} className="pb-2" />
                        <p className="BookingBtnFormText">
                          Clean inside kitchen cabinets
                        </p>
                      </button>
                    </div>
                    <div className="row justify-content-around mt-3">
                      <button
                        id={
                          selectExtra.includes('finished basement')
                            ? 'selectedItemBackground'
                            : null
                        }
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3 "
                        onClick={() => {
                          setSelectExtraHabdler('finished basement');
                        }}
                      >
                        <img src={icon7} className="pb-2" />
                        <p className="BookingBtnFormText">Finished basement</p>
                      </button>
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-3 pb-3 mt-3"
                        id={
                          selectExtra.includes('move-in/out')
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectExtraHabdler('move-in/out');
                        }}
                      >
                        <img src={icon8} className="pb-2" />
                        <p className="BookingBtnFormText">Move-in/out</p>
                      </button>
                    </div>
                  </div>
                  <div className="Line mt-4 mb-4" />

                  <div className="form-group col-md-12 mt-5">
                    <h5 id="formbookingInerheader">
                      How Often Should We Come?
                    </h5>
                    <p id="formbookingInerparagraph">
                      Easily adjust anytime. Recurring discounts follow initial
                      cleaning
                    </p>

                    <div className="row justify-content-around ">
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-4 pb-3 mt-3"
                        id={
                          selectOftenVisit == 'One Time Cleaning'
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectOftenVisit('One Time Cleaning');
                        }}
                      >
                        <p className="BookingBtnFormText">One Time Cleaning</p>
                      </button>
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-4 pb-3 mt-3"
                        id={
                          selectOftenVisit == 'Every Week'
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectOftenVisit('Every Week');
                        }}
                      >
                        <p className="BookingBtnFormText">Every Week</p>
                      </button>
                    </div>

                    <div className="row justify-content-around ">
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-4 pb-3 mt-3 "
                        id={
                          selectOftenVisit == 'Every 2 weeks'
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectOftenVisit('Every 2 weeks');
                        }}
                      >
                        <p className="BookingBtnFormText">Every 2 weeks</p>
                      </button>
                      <button
                        type="button"
                        className="bookingBtnSelectExtra col-md-5 col-sm-12 pt-4 pb-3 mt-3"
                        id={
                          selectOftenVisit == 'Every 4 weeks'
                            ? 'selectedItemBackground'
                            : null
                        }
                        onClick={() => {
                          setSelectOftenVisit('Every 4 weeks');
                        }}
                      >
                        <p className="BookingBtnFormText">Every 4 weeks</p>
                      </button>
                    </div>
                  </div>
                  <div className="Line mt-4 mb-4" />

                  <div className="form-group col-md-12 mt-5">
                    <h5 id="formbookingInerheader">Contact Information</h5>
                    <p id="formbookingInerparagraph">
                      Let us know how we can reach you.
                    </p>
                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="First name"
                      required
                      onChange={(val) => {
                        setFirstName(val.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Last Name"
                      required
                      onChange={(val) => {
                        setLastName(val.target.value);
                      }}
                    />
                    <input
                      type="email"
                      className="form-control mb-4"
                      id="inputEmail4"
                      placeholder="Email"
                      required
                      onChange={(val) => {
                        setEmail(val.target.value);
                      }}
                    />
                    {/* <input
                      type="file"
                      className="form-control mb-4"
                      id="myfile"
                      name="myfile"
                    /> */}
                  </div>
                  <div className="form-group col-md-12 mt-5">
                    <h5 id="formbookingInerheader">Service Address</h5>
                    <p id="formbookingInerparagraph">
                      Where would you like us to clean?
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      placeholder="Address"
                      required
                      onChange={(val) => {
                        setAddress(val.target.value);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control mt-4"
                      placeholder="Apt/Suite"
                      required
                      onChange={(val) => {
                        setAptNo(val.target.value);
                      }}
                    />
                  </div>
                  <div className=" row mt-4">
                    <div className="col-md-4 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault03"
                        placeholder="City"
                        required
                        onChange={(val) => {
                          setCity(val.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault04"
                        placeholder="State"
                        required
                        onChange={(val) => {
                          setState(val.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault05"
                        placeholder="Zip"
                        required
                        onChange={(val) => {
                          setZip(val.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12 mt-5">
                    <h5 id="formbookingInerheader">Book an Appointment</h5>
                    <p id="formbookingInerparagraph">
                      Please choose a date and time that works for you. If your
                      preferred time isn't listed feel free to call us to see if
                      we can make it work.
                    </p>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      placeholder="date"
                      min={moment().format('YYYY-MM-DD')}
                      required
                      onChange={(val) => {
                        setDateVisit(val.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group col-md-12 mt-5">
                    <h5 id="formbookingInerheader">Any Other Request</h5>
                    <textarea
                      className="form-control"
                      rows="5"
                      placeholder="Provide Special Instruction."
                      onChange={(val) => {
                        setInstruction(val.target.value);
                      }}
                    />
                    <p id="formbookingInerparagraph" className="mt-3">
                      By clicking the Book Now button you are agreeing to our
                      Terms of Service and Privacy Policy.
                    </p>
                    <p id="formbookingInerparagraph" className="mt-3">
                      Rest assured, you won't be billed until the day of
                      service. Receive an instant email receipt for your
                      convenience. Please note, we no longer accept cash or
                      checks
                    </p>
                  </div>

                  <div className="d-flex flex-column align-items-center mt-20">
                    <button
                      className="col-8 btnDiscount pt-2 pb-2"
                      type="button"
                      onClick={() => {
                        FormHandler();
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ">
            {/* <div id="shodowcontainer">
              <div className=" d-flex align-items-center">
                <div className="imageIconBooking">
                  <img src={HomeIcon} className="ImageRightBooking img-fluid" />
                </div>
                <p className="rightbookingbottomParagraph">
                  {noOfBedRoom} Bedroom
                </p>
              </div>
              <div className=" d-flex align-items-center">
                <div className="imageIconBooking">
                  <img
                    src={CalendarIcon}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <p className="rightbookingbottomParagraph">
                  {dateVisit ? dateVisit : 'Choose Service Date'}
                </p>
              </div>
              <div className=" d-flex align-items-center">
                <div className="imageIconBooking">
                  <img
                    src={CircleRepeat}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <p className="rightbookingbottomParagraph">
                  {selectOftenVisit ? selectOftenVisit : 'Select Visit Time'}
                </p>
              </div>
            </div> */}
            <div id="shodowcontainer">
              <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={SavesYourTime}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Saves Your Time</h1>
                <p className="paragraphfRightBooking">
                  Yadi’s Clean Solutions helps you live smarter, so you can
                  prioritize what matters most to you.
                </p>
              </div>
              <div className="Line" />
              {/* <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={SafetyFirst}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Safety First</h1>
                <p className="paragraphfRightBooking">
                  Rest assured, our cleaners undergo thorough vetting, including
                  identity and background checks, to ensure your peace of mind
                </p>
              </div> */}
              <div className="Line" />
              <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={OnlyTheBestQuality}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Only The Best Quality</h1>
                <p className="paragraphfRightBooking">
                  Our dedicated cleaners consistently exceed expectations on
                  every job. They are rated and reviewed after each cleaning to
                  ensure exceptional service
                </p>
              </div>
              <div className="Line" />
              <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={EasyToGetHelp}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Easy To Get Help</h1>
                <p className="paragraphfRightBooking">
                  Select bedrooms and bathrooms, choose a date, and let us
                  handle the rest. Sit back and relax as we clean your home to
                  perfection
                </p>
              </div>

              <div className="Line" />
              <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={Seamlesscommunication}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Seamless communication</h1>
                <p className="paragraphfRightBooking">
                  Contact us anytime via call, email, or online chat. Our prompt
                  customer support team ensures an average response time of
                  under 10 minutes
                </p>
              </div>

              <div className="Line" />
              <div className="d-flex flex-column align-items-center ">
                <div className="ImageRightBookingContainer col-4">
                  <img
                    src={CashFreePayment}
                    className="ImageRightBooking img-fluid"
                  />
                </div>
                <h1 className="headindfRightBooking">Cash-Free Payment</h1>
                <p className="paragraphfRightBooking">
                  Pay securely online after the cleaning is complete. Our
                  256-bit SSL encryption technology ensures a safe and
                  hassle-free transaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomComponent />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Booking Success Modal"
      >
        <h1 className="modalHeader">Booking requested</h1>
        <h4 className="modalHeader4">our team will contact you shortly</h4>
        <button
          type="button"
          className="button mb-4 mt-10"
          style={{ width: '80%', height: '20%' }}
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

export default Booking;
