import React, { useRef } from 'react';
import Navbar from '../../Components/NavBar';
import './Services.css';
import BottomComponent from '../../Components/BottomComponent';
import RouteinCleaningSection from '../../Components/RouteinCleaningSection/routeinCleaningSection';
import OvalImage from '../../assets/images/Home/Oval.png';
import BannerRightSquareImage from '../../assets/images/Home/BannerRightSquare.png';
import cleaning2 from '../../assets/images/services/cleaning2.png';
import routeinClean from '../../assets/images/services/routeinClean.jpeg';
import routeinClean2 from '../../assets/images/services/routeinClean2.jpeg';
import bigCleanImg from '../../assets/images/services/bigCleanImg.png';
import PostConstruction from '../../Components/PostConstruction/postConstruction';
function App() {
  const moveinoutRef = useRef(null);
  const HousecleaningRef = useRef(null);
  const Commercialcleaningref = useRef(null);
  const PostconstructioncleaningRef = useRef(null);

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="topContainer">
        <Navbar />
        <div className="container">
          <div className="row col-12">
            <div className="col-md-6 col-sm-12  ">
              <div className="headerTextContainer">
                <h5 className="bannerHeaderh5">Yadi’s Clean Solutions</h5>
                <h3 id="bannerHeaderText" style={{ fontSize: '70px' }}>
                  Experience
                  <span className="headerpink"> Our Premier </span>
                  <span className="headerpink">Services</span>
                  {/* <span className="headerpink">Hassle free Your</span> */}
                </h3>
                <h4 className="bannerHeaderh4">
                  <b>
                    Discover our featured services below. Explore and take
                    advantage of our offerings...!
                  </b>
                </h4>
                {/* <button type="button" className=" buttonHome1">
                Book Now{' '}
              </button>
              <button type="button" className=" buttonHome2">
                Our Services{' '}
              </button> */}
              </div>
            </div>

            <div className="col-md-6  col-sm-12">
              <div className="flex justify-center items-center w-full h-full object-contain">
                <img
                  // className="girlImage"
                  id="girlImage"
                  src={cleaning2}
                  style={{
                    zIndex: 1,
                    height: '90%',
                    width: '90%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-right-img col-md-3 col-sm-3 col-3"
          style={{ zIndex: 0 }}
        >
          <img src={BannerRightSquareImage} alt="" className="img-fluid" />
        </div>
        <div className="top-left-img">
          <img src={OvalImage} alt="" className="img-fluid" />
        </div>
        {/* <div className="bottom-left-img">
          <img src={rectangleImage} alt="" className="img-fluid" />
        </div> */}
      </div>
      <div className="relative bottom-11">
        <div className=" container ">
          <div className="bg-white shadow-lg rounded-xl p-4">
            <div className="d-flex">
              <ul className=" col-md-6">
                <li
                  className="font-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    HousecleaningRef.current.scrollIntoView();
                  }}
                >
                  House cleaning
                </li>
                <li
                  className="font-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    Commercialcleaningref.current.scrollIntoView();
                  }}
                >
                  Commercial cleaning
                </li>
              </ul>
              <ul className=" col-md-6">
                <li
                  className="font-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    moveinoutRef.current.scrollIntoView();
                  }}
                >
                  Move in out Cleaning
                </li>
                <li
                  className="font-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    PostconstructioncleaningRef.current.scrollIntoView();
                  }}
                >
                  Post construction cleaning
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RouteinCleaningSection
          refProp={HousecleaningRef}
          pinkTitle={'Routine'}
          whiteTitle={'Cleaning'}
          paragraph={
            'We will come at your convenience, be it weekly, bi-weekly, or monthly. Our service is dedicated to preserving the cleanliness and tidiness of your office or home.'
          }
          listContent1={'Bringing order to your surroundings'}
          listContent2={
            'Reviving your floors with sweeping, cleaning, and mopping'
          }
          listContent3={
            'Polishing surfaces, appliances, and fixtures to a gleaming shine'
          }
          listContent4={
            'Revitalizing bathrooms with thorough cleaning - bath, toilet, sink, mirrors'
          }
          listContent5={
            'Reviving the kitchen - trash removal and surface cleaning'
          }
          listContent6={'Revitalizing carpets and rugs with expert vacuuming'}
          routeinClean={routeinClean}
        />
      </div>
      <div>
        <RouteinCleaningSection
          refProp={Commercialcleaningref}
          pinkTitle={'Deep'}
          whiteTitle={'Cleaning'}
          paragraph={
            'Our deep cleaning service prioritizes the thorough cleaning of bathroom and kitchen areas. It goes beyond regular cleaning by targeting hard-to-reach places, such as under sinks, behind bookshelves, and inside cabinets, to eliminate stubborn grime and dirt'
          }
          listContent1={'Refreshing closets with meticulous interior wiping'}
          listContent2={'Organize drawers and wipe cabinets interiors'}
          listContent3={`Revive the oven's interior with a thorough cleaning`}
          listContent4={
            'Refresh the fridge and freezer with a meticulous interior wipe'
          }
          listContent5={'Revitalize walls with targeted spot cleaning'}
          listContent6={'Revive blinds with gentle dusting or washing'}
          listContent7={'Revitalize baseboards with a thorough scrubbing'}
          routeinClean={routeinClean2}
          rowReverse={true}
        />
      </div>
      <section className="bg-[#F9F4D4]" ref={moveinoutRef}>
        <div className="  items-center">
          {/* <div className="LeftArrowImg col-md-4">
            <img src={LeftArrowImg} className="img-fluid" />
          </div> */}
          {/* <div className="imagemoveinsideleft ">
            <img src={LeftArrowImg} className="img-fluid" />
          </div> */}

          <div className=" w-full pt-5 pb-5">
            <h3 className="text-black text-center  lg:text-6xl sm:text-4xl   font-semibold">
              Move
              <span className="headerpink"> In </span>
              or
              <span className="headerpink"> Out </span>
              Cleaning
            </h3>
          </div>
        </div>
        <div className=" w-full flex justify-center">
          <div className="w-[80%]  object-contain bg-white flex items-center justify-center p-3 rounded-lg">
            <img
              src={bigCleanImg}
              alt="/"
              className="w-full h-full object-contain"
            />
          </div>
          {/* <div className="imagemoveinsideRight ">
            <img src={RightArrowImg} className="img-fluid" />
          </div> */}
        </div>

        <div className="container ">
          <p
            className="pt-10 pb-10 "
            style={{ textAlign: 'center', fontSize: '19px' }}
          >
            Make your move stress-free with our vacancy cleaning service. We
            understand that moving can be overwhelming, which is why hiring us
            for a thorough deep clean before your move-in can alleviate the
            stress. Our move-in or move-out cleaning services already encompass
            various deep cleaning tasks. If you have specific requirements or
            priorities, be sure to discuss them during the booking process so we
            can prepare accordingly.
          </p>
        </div>
      </section>
      <div>
        <PostConstruction refProp={PostconstructioncleaningRef} />
      </div>
      <BottomComponent />
    </div>
  );
}

export default App;
