import React, { useState } from "react";
import "./Style.css";
import WhyChoseUsImage from "../../assets/images/WhyChoseUs.png";
import EllipseImage from "../../assets/images/Home/Ellipse.png";
import LeftArrowImage from "../../assets/images/Home/LeftArrow.png";
import RightArrowImage from "../../assets/images/Home/RightArrow.png";
const SayGoodBy = ({}) => {
  return (
    <div className=" topContainerSayGoodby">
      <div className="container  d-flex flex-column align-items-center ">
        <div className="botom-left-img-say ">
          <img src={RightArrowImage} className="img-fluid" />
        </div>
        <h1 className="sayGoodbyh1 mb-3" style={{ fontWeight: "bold" }}>
          SAY
          <span style={{ color: "#E23074" }}> GOODBYE </span>
          TO MESS & DIRT EVERY
          <span style={{ color: "#E23074" }}> TIME </span>
        </h1>
        <h4 className="sayGoodbyp">
          Maximize your savings and efficiency with our convenient online
          booking and competitive pricing!
        </h4>
        <button
          type="button"
          className="button mb-4 mt-10"
          style={{ width: "70%", height: "20%" }}
          onClick={() => {
            window.location.href = "/services";
          }}
        >
          Services
        </button>
        <button
          // type="button"
          className="button2 "
          style={{ width: "70%", height: "20%" }}
          onClick={() => {
            window.location.href = "/booking";
          }}
        >
          Book Now
        </button>
        <h2 style={{ marginTop: "3%", fontWeight: "bold" }}>
          .........OR give us a call directly at (346) 755 2321
        </h2>

        <div className="top-right-img-say">
          <img src={LeftArrowImage} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default SayGoodBy;
