import React from 'react';
import postCleaning from '../../assets/images/services/postCleaning.jpeg';
const PostConstruction = ({ refProp }) => {
  return (
    <section className="py-4" ref={refProp}>
      <div className=" w-full">
        <h3 className="text-black text-center  lg:text-6xl sm:text-4xl   font-semibold">
          Post
          <span className="headerpink"> Construction </span>
          Cleaning
        </h3>
      </div>
      <div className="w-full my-10 pl-5 pr-5">
        <p className="text-2xl text-center md:pl-20 md:pr-20 sm:pl-10 sm:pr-10" >
          Experience the difference with our post-construction cleaning service.
          Beyond general debris cleanup, we provide meticulous and detailed
          cleaning to ensure your space is spotless and ready for use. Sit back
          and enjoy the results of your project without the hassle of cleaning
          up
        </p>
      </div>
      <div className="container mx-auto w-full md:max-w-screen-md h-400px flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 object-contain">
          <img
            src={postCleaning}
            alt="Your Image"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="w-full  md:w-1/2 flex ">
          <ul className=" flex flex-col justify-evenly  ">
            <li className="md:text-3xl md:text-1xl pt-4 pb-3">
              Spotless walls, free from marks, dirt, and smudges
            </li>

            <li className="md:text-3xl md:text-1xl pb-3">
              Remove plastic/stickers from furniture, windows, and doors
            </li>
            <li className="md:text-3xl md:text-1xl pb-3">
              Revive windows and frames with precision
            </li>
            <li className="md:text-3xl md:text-1xl ">
              Dusting, wet and dry, of ceilings, lights, fans, heaters
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto max-w-screen-md">
        <ul className=" pl-8 flex flex-col flex-wrap ">
          <li className="md:text-4xl md:text-1xl my-md-4">
            Flawless floors, debris-free, clean, and polished
          </li>
        </ul>
        <ul className=" pl-8 md:flex lf:flex justify-between lg:flex-row sm:flex-col flex-wrap ">
          <li className="md:text-4xl md:text-1xl my-md-4 pb-3">Vacuum cleaning</li>
          <li className="md:text-4xl md:text-1xl my-md-4 pb-3">Trim cleaning</li>
          <li className="md:text-4xl md:text-1xl my-md-4 pb-1">Threshold cleaning</li>
        </ul>
        <ul className=" d-md-flex justify-content-md-between">
          <li className="md:text-4xl md:text-1xl my-md-4 col-md-6  pb-3">
            Cleaning of all appliances, cabinets, and shelves from inside and
            out.
          </li>
          <li className="md:text-4xl md:text-1xl my-md-4 col-md-5 ">
            Trim Proper disposal of trash and debris cleaning
          </li>
        </ul>
        <ul className=" pl-8 flex flex-col flex-wrap ">
          <li className="md:text-4xl md:text-1xl my-md-4 pb-3">
            Addressing any safety issues, loose wires, and leaks, to the
            construction contractors
          </li>
          <li className="md:text-4xl md:text-1xl my-md-4 ">
            Detailed checking, for the last time, of all the space, corners and
            inner surfaces once the cleaning is completely done.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PostConstruction;
