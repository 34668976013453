import React from 'react';
import Navbar from '../../Components/NavBar';
import './About.css';
import BottomComponent from '../../Components/BottomComponent';
import ServicesGirlImage from '../../assets/images/ServicesGirlImage.png';
import rectangleImage from '../../assets/images/Home/rectangle.png';
import OvalImage from '../../assets/images/Home/Oval.png';
import BannerRightSquareImage from '../../assets/images/Home/BannerRightSquare.png';
import AboutUsFirstComponent from '../../Components/AboutUsFirstComponent';
import AboutUsSecondComponent from '../../Components/AboutUsSecondComponent';

function App() {
  return (
    <div>
      <div className="topContainer" id={'headerdiv'}>
        <div className="container">
          <Navbar />
          <div className="row col-12">
            <div className="col-md-6 col-sm-12">
              <div className="headerTextContainer">
                <h5 className="bannerHeaderh5">Yadi’s Clean Solutions</h5>
                <h1 id="bannerHeaderText">
                  <span
                    className="headerpink"
                    style={{ textDecoration: 'underline' }}
                  >
                    Experience{' '}
                  </span>
                  the magic of a sparkling haven with our
                  <span
                    className="headerpink"
                    style={{ textDecoration: 'underline' }}
                  >
                    {' '}
                    top-notch cleaning{' '}
                  </span>
                  solutions
                </h1>
                <h4 className="bannerHeaderh4 mt-5">
                  <b>Explore more About Us. </b>
                </h4>
              </div>
            </div>

            <div className="col-md-6  col-sm-12">
              <div className="d-flex justify-content-center col-md-10">
                <img
                  src={ServicesGirlImage}
                  style={{
                    zIndex: 6,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="top-right-img col-md-3 col-sm-3 col-3">
          <img
            src={BannerRightSquareImage}
            alt=""
            className="img-fluid"
            style={{ zIndex: 1 }}
          />
        </div>
        <div className="top-left-img">
          <img src={OvalImage} alt="" className="img-fluid" />
        </div>
      </div>
      <AboutUsFirstComponent />
      <AboutUsSecondComponent />

      <BottomComponent />
    </div>
  );
}

export default App;
