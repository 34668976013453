import React, { useState } from 'react';
import './Style.css';
import WhyChoseUsImage from '../../assets/images/WhyChoseUs.png';
import EllipseImage from '../../assets/images/Home/Ellipse.png';
import LeftArrowImage from '../../assets/images/Home/LeftArrow.png';
import RightArrowImage from '../../assets/images/Home/RightArrow.png';
import AboutUsGroupImage from '../../assets/images/AboutUsGroup.png';
const WhyChoseUs = ({}) => {
  return (
    <div className="container">
      <div className="bottom-left-img-why">
        <img src={LeftArrowImage} className="img-fluid" />
      </div>
      <div className="mainContainerwhycoiceus">
        <div className="headerStyleAboutUs">
          <div className="">
            Yadi’s Clean Solutions, your satisfaction guaranteed!
          </div>
        </div>

        <p className="aboutUsParagraph marginText">
          We are dedicated to exceeding your expectations with exceptional
          cleaning services tailored to your needs. Our company was built on the
          foundation of delivering superior cleaning solutions, infused with a
          personal touch.
        </p>
        <p className="aboutUsParagraph">
          We recognize that your home or business is your sanctuary, and
          maintaining its cleanliness is paramount to you. That's why we provide
          a comprehensive range of cleaning services that cater to your unique
          needs and preferences. Whether you require regular house cleaning or
          deep cleaning, we've got you covered every step of the way.
        </p>

        <div className="row ">
          <div className="col-md-5 col-sm-12 ">
            <div className="d-flex  ">
              <div className="top-right-img-why">
                <img src={RightArrowImage} className="img-fluid" />
              </div>
              <img
                src={AboutUsGroupImage}
                className="image-fluid"
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="">
              <p className="aboutUsParagraph marginText">
                Our skilled cleaning team is equipped with state-of-the-art
                tools and techniques to provide exceptional service. We take
                pride in delivering top-quality results that leave your space
                spotless and refreshed. Rest easy knowing that we prioritize
                your well-being and the environment by exclusively using
                eco-friendly cleaning products, safe for your family and pets.
              </p>
              <p className="aboutUsParagraph marginText">
                At Yadi’s Clean Solutions, our commitment is to deliver
                excellent customer service that exceeds your expectations. We
                genuinely listen to your needs and customize our services
                accordingly. We understand the value of your time, so we work
                diligently and efficiently to get the job done right. Building
                strong, lasting relationships with our clients is a source of
                pride for us, and we always go above and beyond to ensure your
                utmost satisfaction.
              </p>
            </div>
          </div>
        </div>
        <p className="aboutUsParagraph marginText">
          For all your cleaning needs, whether it's your home or business, make
          Yadi’s Clean Solutions your go-to choice. Reach out to us today to
          book your cleaning appointment and discover the transformative
          difference we can bring to your space.
        </p>
        <div className="row align-items-center">
          <div className="col-md-7 col-sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoseUs;
