import React, { useState } from 'react';
import './Style.css';
import slide1 from '../../assets/Icon/Service1.png';
import slide2 from '../../assets/Icon/Service2.png';
import slide3 from '../../assets/Icon/Service3.png';
import slide4 from '../../assets/Icon/Service4.png';

import Card from './Card';
const AdvancedCarousel = ({ sliderData }) => {
  const sliderItems = sliderData
    ? sliderData
    : [
        {
          id: 1,
          text: 'Routine Cleaning',
          text1:
            'We offer flexible scheduling options tailored to your needs. Whether you prefer a weekly, bi-weekly, or monthly arrangement, we can accommodate your schedule with ease',
          img: slide1,
        },
        {
          id: 2,
          text: 'Deep Cleaning',
          text1:
            'Our deep cleaning services give special attention to your bathroom and kitchen areas, ensuring spotless & gleaming environment',
          img: slide2,
        },
        {
          id: 3,
          text: 'Move-In Or Out Cleaning',
          text1:
            'Make your move stress-free by hiring us for a thorough deep clean beforehand',
          img: slide3,
        },
        {
          id: 4,
          text: 'Post Construction Cleaning',
          text1:
            'Post-construction cleaning ensures a pristine environment after the building phase',
          img: slide4,
        },
      ];

  return (
    <div className="mainContainer">
      <div className="row row-cols-auto d-flex justify-content-around">
        {sliderItems.map((item, index) => (
          <div key={item.id} className="col ">
            <Card img={item.img} headerText={item.text} BodyText={item.text1} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvancedCarousel;
